import { createApp } from 'vue'
import App from './App.vue'
import  "bootstrap/dist/js/bootstrap"
import router from './router'
import FontAwesomeIcon from "./utilities/fontawesome-icons";
import  "./main.scss"
import VueRecaptcha from "vue3-recaptcha-v2";

createApp(App)
    .use(router)
    .use(VueRecaptcha, {
        siteKey: "6LdkfqwcAAAAAOWO_TIaUeuPw24DxzkpYv83Yil8"
    }).
    component("font-awesome-icon", FontAwesomeIcon).mount('#app')
