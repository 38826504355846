<template>
    <main>
        <router-view/>
    </main>
</template>

<script>
    export default {
        name: 'Header',
        components: {
        }
    }
</script>
<style lang="scss">
    main {
        transition: all 0.8s cubic-bezier(.55,0,.1,1);
        height: calc(100vh - 78px);
    }
</style>