<template>
    <div class="container">
        <div class="section-header">
            <h1 class="title">My Skills</h1>
            <p class="description">
                I am a Web developer with experience in building websites and web applications.
                I do both Frontend and Backend and have professional experience working with PHP, Laravel, Wordpress and Vue . I also have experience working in mobile apps using React native, Cordova, Android native.
                furthermore I have good understanding in most web developement related fields like SEO, UI design, Software design, Web Server management..
 </p>
        </div>
        <div class="row">
            <div class="col-md-6 col-lg-4 col-sm-12 d-flex align-items-stretch">
                <div class="card mb-5">
                    <div class="card-body">
                        <h5 class="card-title">Programming Languages</h5>
                        <h6 class="card-subtitle mb-2">Mastered</h6>
                        <ul class="list-data">
                            <li>
                                <font-awesome-icon class="icon" icon="angle-right"/>
                                PHP
                            </li>
                            <li>
                                <font-awesome-icon class="icon" icon="angle-right"/>
                                Javascript
                            </li>
                            <li>
                                <font-awesome-icon class="icon" icon="angle-right"/>
                                Typescript
                            </li>
                            <li>
                                <font-awesome-icon class="icon" icon="angle-right"/>
                                SQL
                            </li>
                        </ul>
                        <h6 class="card-subtitle mb-2">Medium level</h6>
                        <ul class="list-data">
                            <li>
                                <font-awesome-icon class="icon" icon="angle-right"/>
                                Java
                            </li>
                            <li>
                                <font-awesome-icon class="icon" icon="angle-right"/>
                                Python
                            </li>
                            <li>
                                <font-awesome-icon class="icon" icon="angle-right"/>
                                C
                            </li>
                        </ul>
                        <h6 class="card-subtitle mb-2">Used it few time</h6>
                        <ul class="list-data">
                            <li>
                                <font-awesome-icon class="icon" icon="angle-right"/>
                                C#
                            </li>
                            <li>
                                <font-awesome-icon class="icon" icon="angle-right"/>
                                Shell linux
                            </li>
                            <li>
                                <font-awesome-icon class="icon" icon="angle-right"/>
                                PL/SQL
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4 col-sm-12 d-flex align-items-stretch">
                <div class="card mb-5">
                    <div class="card-body">
                        <h5 class="card-title">Frameworks/Libraries</h5>
                        <h6 class="card-subtitle mb-2">Mastered</h6>
                        <ul class="list-data">
                            <li>
                                <font-awesome-icon class="icon" icon="angle-right"/>
                                Laravel
                            </li>
                            <li>
                                <font-awesome-icon class="icon" icon="angle-right"/>
                                WordPress
                            </li>
                            <li>
                                <font-awesome-icon class="icon" icon="angle-right"/>
                                Codeigniter
                            </li>
                            <li>
                                <font-awesome-icon class="icon" icon="angle-right"/>
                                Vue.js
                            </li>
                            <li>
                                <font-awesome-icon class="icon" icon="angle-right"/>
                                Bootstrap
                            </li>
                            <li>
                                <font-awesome-icon class="icon" icon="angle-right"/>
                                jQuery
                            </li>
                        </ul>
                        <h6 class="card-subtitle mb-2">Used it few time</h6>
                        <ul class="list-data">
                            <li>
                                <font-awesome-icon class="icon" icon="angle-right"/>
                                Angular
                            </li>
                            <li>
                                <font-awesome-icon class="icon" icon="angle-right"/>
                                React native
                            </li>
                            <li>
                                <font-awesome-icon class="icon" icon="angle-right"/>
                                Phaser
                            </li>
                            <li>
                                <font-awesome-icon class="icon" icon="angle-right"/>
                                Framework7
                            </li>
                        </ul>
                        <h6 class="card-subtitle mb-2">Related</h6>
                        <ul class="list-data">
                            <li>
                                <font-awesome-icon class="icon" icon="angle-right"/>
                                REST API
                            </li>
                            <li>
                                <font-awesome-icon class="icon" icon="angle-right"/>
                                JSON
                            </li>
                            <li>
                                <font-awesome-icon class="icon" icon="angle-right"/>
                                XML
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4 col-sm-12 d-flex align-items-stretch">
                <div class="card mb-5">
                    <div class="card-body">
                        <h5 class="card-title">Database</h5>
                        <h6 class="card-subtitle mb-2">Mastered</h6>
                        <ul class="list-data">
                            <li>
                                <font-awesome-icon class="icon" icon="angle-right"/>
                                MySQL
                            </li>
                        </ul>
                        <h6 class="card-subtitle mb-2">Medium level</h6>
                        <ul class="list-data">
                            <li>
                                <font-awesome-icon class="icon" icon="angle-right"/>
                                MongoDB
                            </li>
                            <li>
                                <font-awesome-icon class="icon" icon="angle-right"/>
                                Oracle SQL
                            </li>
                            <li>
                                <font-awesome-icon class="icon" icon="angle-right"/>
                                SQLite
                            </li>
                        </ul>
                        <h6 class="card-subtitle mb-2">Used it few time</h6>
                        <ul class="list-data">
                            <li>
                                <font-awesome-icon class="icon" icon="angle-right"/>
                                SQL Server
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4 col-sm-12 d-flex align-items-stretch">
                <div class="card mb-5">
                    <div class="card-body">
                        <h5 class="card-title">Servers Related</h5>
                        <h6 class="card-subtitle mb-2">Skills</h6>
                        <ul class="list-data rows">
                            <li>
                                <font-awesome-icon class="icon" icon="angle-right"/>
                                Manage Web servers (Apache, Nginx..)
                            </li>
                            <li>
                                <font-awesome-icon class="icon" icon="angle-right"/>
                                Domains DNS management
                            </li>
                            <li>
                                <font-awesome-icon class="icon" icon="angle-right"/>
                                Deployment of web/mobile applications
                            </li>
                        </ul>
                        <h6 class="card-subtitle mb-2">Tools</h6>
                        <ul class="list-data">
                            <li>
                                <font-awesome-icon class="icon" icon="angle-right"/>
                                Git
                            </li>
                            <li>
                                <font-awesome-icon class="icon" icon="angle-right"/>
                                Github
                            </li>
                            <li>
                                <font-awesome-icon class="icon" icon="angle-right"/>
                                Docker
                            </li>
                            <li>
                                <font-awesome-icon class="icon" icon="angle-right"/>
                                WHM/CPanel
                            </li>
                            <li>
                                <font-awesome-icon class="icon" icon="angle-right"/>
                                Virtualmin
                            </li>
                            <li>
                                <font-awesome-icon class="icon" icon="angle-right"/>
                                Cloudflare
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4 col-sm-12 d-flex align-items-stretch">
                <div class="card mb-5">
                    <div class="card-body">
                        <h5 class="card-title">Mobile</h5>
                        <ul class="list-data rows">
                            <li>
                                <font-awesome-icon class="icon" icon="angle-right"/>
                                Native applications  - Android(Java)
                            </li>
                            <li>
                                <font-awesome-icon class="icon" icon="angle-right"/>
                                Cordova Hybrid applications - Android/IOS
                            </li>
                            <li>
                                <font-awesome-icon class="icon" icon="angle-right"/>
                                React Native applications - Android/IOS
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4 col-sm-12 d-flex align-items-stretch">
                <div class="card mb-5">
                    <div class="card-body">
                        <h5 class="card-title">Other</h5>
                        <ul class="list-data">
                            <li>
                                <font-awesome-icon class="icon" icon="angle-right"/>
                                SEO
                            </li>
                            <li>
                                <font-awesome-icon class="icon" icon="angle-right"/>
                                UML
                            </li>
                            <li>
                                <font-awesome-icon class="icon" icon="angle-right"/>
                                UI design
                            </li>
                            <li>
                                <font-awesome-icon class="icon" icon="angle-right"/>
                                Game dev
                            </li>
                            <li>
                                <font-awesome-icon class="icon" icon="angle-right"/>
                                Machine learning
                            </li>
                            <li>
                                <font-awesome-icon class="icon" icon="angle-right"/>
                                Familiar with Agile Scrum
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Skills',
    }
    /*
                    <br/>

                I'm very passionate about programming that I learn new technologies each year. I've worked in many projects that require to use different technologies, and I always adapt and get the job done.
                <br/>
                Not just programming, as I was a freelancer, I had to do the meetings, plan, design, develop, test and deploy everything by myself so I know at least basics of most domains related to software development.

     */
</script>
<style lang="scss" scoped>
    .container {
        display: flex;
        align-items: center;
        min-height: 100%;
        flex-direction: column;
        .section-header {
            margin-top: 2rem;
            margin-bottom: 2rem;
            text-align: center;
            max-width: 700px;
            .title {
                color: var(--text-color);
                position: relative;
                padding-bottom: 1rem;
                font-weight: bold;
                &:after {
                    content: '';
                    background-color: var(--primary-color);
                    height: 3px;
                    width: 160px;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    margin: 0 auto;
                }
            }
            .description {
                color: var(--text-color);
            }
        }
    }

    .card {
        box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
        background-color: var(--card-bg-color);
        border-radius: 5px;
        color: var(--text-color);
        width: 100%;
        border: 2px solid var(--primary-color);
        .card-title {
            font-weight: bold;
            color: var(--primary-color);
            position: relative;
            padding-bottom: 18px;
            margin-bottom: 20px;

            &:after {
                content: '';
                background-color: var(--primary-color);
                height: 3px;
                width: 100%;
                position: absolute;
                left: 0;
                bottom: 0;
            }
        }

        .card-subtitle {
            color: var(--text-color);
            font-weight: bold;
        }

        .list-data {
            &.rows {
                li {
                    max-width: inherit;
                }
            }

            li {
                width: 100%;
                max-width: 9rem;
                display: inline-flex;

                .icon {
                    margin-right: .5rem;
                    margin-top: .3rem;
                }
            }
        }

    }

    @media (max-width: 992px) {
        .container {
            padding-top: 2rem;
            height: auto;
        }
    }
</style>