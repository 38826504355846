<template>
    <div class="container">
        <div class="section-header">
            <h1 class="title">Some of my works</h1>
            <p class="description">
                I've done a lot of different projects in my professional careers and as freelancer, 
                I picked best projects that I've done as freelancer, feel free to contact me if you want check demo of them.
            </p>
        </div>
        <div class="projects">
            <div v-for="(project,index) in projects" :key="index" class="project"
                 :class="{active: activeProject===index}" @click="switchProject(index)"
                 :style="{backgroundImage:'url('+require('@/assets/'+project.banner)+')'}">
                <div class="mask"></div>
                <div class="card">
                    <img class="card-img-top" :src="require('@/assets/'+project.banner)">
                    <div class="card-body">
                        <h5 class="card-title">
                            {{project.title}}
                        </h5>
                        <p class="card-text" v-html="project.details"></p>
                    </div>
                    <div class="card-footer">
                        <div class="badge tool-badge" v-for="(tool,index) in project.tools" :key="index">{{tool}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section-header">
            <h1 class="title">Last work</h1>
            <div class="card mt-3">
                <img class="card-img-top" :src="require('@/assets/banner-spinner-ufo.jpg')">
                <div class="card-body">
                    <h5 class="card-title">
                        Game : Spinner UFO
                    </h5>
                    <p class="card-text">
                        Spinner UFO is browser game I made it for <a href="https://gamejolt.com/c/gamemaker/ogxgj" target="_blank">OperaGX game jam</a> competition using gamemaker studio 2(first time) , I couldn't make it to the top but was great experience.
                    <a class="btn btn-primary btn-sm" href="https://gamejolt.com/games/spinner-ufos/638706" target="_blank">Play It Now</a>
                    </p>
                </div>
                <div class="card-footer">
                    <div class="badge tool-badge">Gamemaker Studio 2</div>
                    <div class="badge tool-badge">GML</div>
                    <div class="badge tool-badge">Photoshop</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'Projects',
        data: function () {
            return {
                activeProject:0,
                projects: [
                    {
                        'title': 'School Quiz App',
                        'details': 'Android app installed in the tablets of school, and students can pass quizzes of the books in the library as follows:<br/>' +
                            'Students connect to their account using QR code in their school card.<br/>' +
                            'And then they can see their score and ranking, scan QR code of a book to pass its quiz and earn or lose points.<br/>' +
                            'In the backend there is web application to manage students and books, quizzes, statistics, print QR code cards and ranking posters.',
                        'banner': 'banner-quizz.jpg',
                        'tools': [
                            'Laravel',
                            'HTML/CSS/JS',
                            'Java',
                            'Android SDK'
                        ],
                    },
                    {
                        'title': 'News App PAMGROUP',
                        'details': 'React Native News app for Android and IOS that works with WordPress REST API and Firebase Push Notification.<br/>' +
                            'A WordPress plugin to customize menu categories of the app.',
                        'banner': 'banner-pam.jpg',
                        'tools': [
                            'WordPress',
                            'React Native(Android/IOS)',
                            'Firebase'
                        ],
                    },
                    {
                        'title': 'News App ATLASINFO',
                        'details': 'Hybrid News app for Android and IOS that works with WordPress REST API and Firebase Push Notification.<br/>' +
                            'A WordPress plugin to customize menu categories of the app.' +
                            '<br/><a class="btn btn-primary btn-sm" href="https://play.google.com/store/apps/details?id=com.atlasinfo.app" target="_blank">In Google Play</a> ' +
                            '<a class="btn btn-primary btn-sm ml-1 " href="https://apps.apple.com/us/app/atlasinfo/id1495363393?l=lv" target="_blank">In App Store</a>',
                        'banner': 'banner-atlasinfo.jpg',
                        'tools': [
                            'WordPress',
                            'Vue.js',
                            'Cordova(Android/IOS)',
                            'Firebase',
                            'APN'
                        ],
                    },
                    {
                        'title': 'Automotive expert management system',
                        'details': 'An automotive expert management system that includes: ' +
                            'Missions management, Generate documents. Photos and documents saving, Calculate compensation, ' +
                            'Users multiple roles, Tracking, Statistics, E-mail/SMS Notifications <br/>' +
                            'A mobile app to receive missions notification to fill insured information and takes photos of accidents.<br/>' +
                            'Deployed in Google App Engine.',
                        'banner': 'banner-ems.jpg',
                        'tools': [
                            'Codeigniter',
                            'HTML/CSS/JS',
                            'Cordova',
                            'Google Cloud App Engine, GC Storage, GC Database'
                        ],
                    },
                    {
                        'title': 'Transport management system',
                        'details': 'A Transport management system to manage packages transport and track it.<br/>' +
                            'A mobile app that enable customers to order package transport service(payment online with Stripe) and track their package.',
                        'banner': 'banner-phoenix.jpg',
                        'tools': [
                            'Laravel',
                            'HTML/CSS/JS',
                            'Vue.js',
                            'Cordova',
                        ],
                    },
                    {
                        'title': 'Meeting management system',
                        'details': 'A web application to create, plan and join meetings integrated with customized Jitsi docker container.',
                        'banner': 'banner-meeting.jpg',
                        'tools': [
                            'Laravel',
                            'HTML/CSS/JS',
                            'Jitsi',
                            'Docker',
                        ],
                    },
                ]
            }
        },
        methods: {
            switchProject: function (index) {
                if(this.activeProject!==index) {
                    this.activeProject = index;
                }
            },
        }
    }
</script>
<style lang="scss" scoped>
    .container {
        display: flex;
        align-items: center;
        min-height: 100%;
        flex-direction: column;
        .section-header {
            margin-top: 2rem;
            margin-bottom: 2rem;
            text-align: center;
            max-width: 700px;
            .title {
                color: var(--text-color);
                position: relative;
                padding-bottom: 1rem;
                font-weight: bold;
                &:after {
                    content: '';
                    background-color: var(--primary-color);
                    height: 3px;
                    width: 160px;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    margin: 0 auto;
                }
            }
            .description {
                color: var(--text-color);
            }
        }
    }
    .card {
        box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
        background-color: var(--card-bg-color);
        border-radius: 5px;
        color: var(--text-color);
        width: 100%;
        border: 2px solid var(--primary-color);
        text-align: left;
        .card-title {
            font-weight: bold;
            color: var(--primary-color);
            position: relative;
            padding-bottom: 18px;
            margin-bottom: 20px;

            &:after {
                content: '';
                background-color: var(--primary-color);
                height: 3px;
                width: 100%;
                position: absolute;
                left: 0;
                bottom: 0;
            }
        }
        .card-text {
            width: 620px;
        }
        .tool-badge {
            background-color: var(--badge-bg-color);
            color: var(--badge-text-color);
            margin-left: .2rem;
        }
    }
    .projects {
        display: flex;
        flex-direction: row;
        min-width: 600px;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        min-height: 800px;

        .project {
            position: relative;
            overflow: hidden;
            max-width: 60px;
            margin: 10px;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            cursor: pointer;
            transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
            transition-property: width, max-width;
            width: 100%;
            border: 2px solid var(--primary-color);
            height: 653px;
            .mask {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                height: 100%;
                background-color: var(--secondary-color)cf;
            }
            &:not(.active) {
                flex-grow: 1;
                .card {
                    display: none;
                    border: none;
                }
            }

            &.active {
                flex-grow: 10000;
                transform: scale(1);
                max-width: 650px;
                margin: 0;
                background-size: auto 100%;
                overflow: visible;
                background-image: none !important;
                border: none;
                height: 100%;
                .mask {
                    display: none;
                }
                .card {
                    display: flex;
                    .card-text {
                        animation: fadeInBottom .8s;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1400px) {
        .projects {
            .project {
                height: 520px;
            }
        }
        .card-text {
            width: 620px;
        }
    }
    @media screen and (max-width: 1200px) {
        .projects {
            .project {
                height: 390px;
            }
        }
        .card-text {
            width: 500px;
        }
    }
    @media screen and (max-width: 991px) {
        .projects {
            flex-direction: column;
            height: auto;
            width: 100%;
            min-width: inherit;
            align-items: center;
            .project {
                width: 100%;
                flex-grow: inherit !important;
                max-width: inherit !important;
                margin: 0 0 1rem 0 !important;
                overflow: visible;
                border: none;
                height: 100%;
                .mask {
                    display: none !important;
                }
            }
        }
        .card {
            display: flex !important;
            border: 2px solid var(--primary-color) !important;
            .card-text {
                width: 100%;
            }
        }
    }
    @keyframes fadeInBottom {
        0% {
            opacity: 0;
            transform: translateY(50%);
        }
        30% {
            opacity: 0;
            transform: translateY(100%);
        }
        100% { opacity: 1 }
    }

    @media (max-width: 992px) {
        .container {
            padding-top: 2rem;
            height: auto;
        }
    }
</style>