<template>
    <div class="container">
        <div class="section-header">
            <h1 class="title">Get In Touch</h1>
            <p class="description">
                Have a question? Concern? Request? Feel free to contact me through my e-mail address
                <a href="mailto:youssef0hamdane@gmail.com">youssef0hamdane@gmail.com</a> or form below.
            </p>
        </div>
        <div class="card">
            <div class="card-body">
                <div class="contact-form">
                    <div class="form-group">
                        <input type="text" class="form-control" placeholder="Name" name="name" v-model="model.name">
                    </div>
                    <div class="form-group">
                        <input type="text" class="form-control" placeholder="E-mail" name="email" v-model="model.email">
                    </div>
                    <div class="form-group">
                        <textarea class="form-control" placeholder="Message" name="message" rows="5"  v-model="model.message"></textarea>
                    </div>

                    <div class="form-group">
                    <vue-recaptcha
                            theme="light"
                            size="normal"
                            :tabindex="0"
                            @verify="captchaCallbackVerify($event)"
                            @expired="captchaCallbackExpired()"
                    />
                    </div>
                    <div class="submit-form">
                        <button class="btn btn-submit" @click="sendMessage" :disabled="loading">Submit</button>
                    </div>
                    <div class="alert alert-danger" role="alert" v-if="error">
                        {{error}}
                    </div>
                    <div class="alert alert-success" role="alert"  v-if="sent">
                        Your message has been sent!
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {VueRecaptcha} from "vue3-recaptcha-v2";
    import axios from 'axios';
    export default {
        name: 'Contact',
        components: { VueRecaptcha },
        data: function() {
          return {
              model: {
                  name:'',
                  email:'',
                  message:'',
                  recaptcha: null,
              },
              url: 'https://youssefhamdane.com/backend/send-email.php',
              sent: false,
              error: false,
              loading: false
          }
        },
        methods: {
            captchaCallbackExpired: function() {
                this.model.recaptcha = null;
            },
            captchaCallbackVerify: function(response) {
              this.model.recaptcha = response;
            },
            sendMessage: function() {
                const self = this;
                if(this.model.name==='')
                    this.error = 'Name is required';
                else if(this.model.email==='')
                    this.error = 'E-mail is required';
                else if(this.model.recaptcha === null)
                    this.error = 'Recaptcha validation is required'
                if(this.error !== false)
                    setTimeout(function () {
                        self.error = false
                    },3000);
                else {
                    this.loading = true;
                    axios.post(this.url, this.model)
                        .then(function (response) {
                            console.log(response);
                            self.sent = true;
                            setTimeout(function () {
                                self.sent = false
                            },3000);
                        })
                        .catch(function (error) {
                            console.log(error);
                        })
                    .finally(function () {
                        self.loading = false;
                    });
                }
            }
        }
    }
</script>
<style lang="scss" scoped>
    .container {
        display: flex;
        align-items: center;
        flex-direction: column;
        .section-header {
            margin-top: 2rem;
            margin-bottom: 2rem;
            text-align: center;
            max-width: 700px;
            .title {
                color: var(--text-color);
                position: relative;
                padding-bottom: 1rem;
                font-weight: bold;
                &:after {
                    content: '';
                    background-color: var(--primary-color);
                    height: 3px;
                    width: 160px;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    margin: 0 auto;
                }
            }
            .description {
                color: var(--text-color);
            }
        }
        .card {
            box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
            background-color: var(--card-bg-color);
            border-radius: 5px;
            color: var(--text-color);
            width: 100%;
            border: 2px solid var(--primary-color);

            max-width: 500px;

            .card-title {
                font-weight: bold;
                color: var(--primary-color);
                position: relative;
                padding-bottom: 18px;
                margin-bottom: 20px;

                &:after {
                    content: '';
                    background-color: var(--primary-color);
                    height: 3px;
                    width: 100%;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                }
            }
            .contact-form {
                .form-group {
                    padding: .6rem;
                    .form-control {
                        background-color: var(--secondary-alt-color);
                        border:1px solid var(--primary-color);
                        color: var(--text-color);
                        padding: 1.2rem;
                    }
                }
                .submit-form {
                    display: flex;
                    justify-content: right;
                    padding: 1rem;
                    .btn-submit {
                        background-color: var(--badge-bg-color);
                        color:var(--badge-text-color);
                        padding-left: 2rem;
                        padding-right: 2rem;
                    }
                }
            }
        }
    }
    @media (max-width: 992px) {
        .container {
            padding-top: 2rem;
            height: auto;
        }
    }
</style>