<template>
    <div class="bg-stars">
        <div id="bg-stars1"></div>
        <div id="bg-stars2"></div>
        <div id="bg-stars3"></div>
    </div>
    <div ref="animatedBg" class="animated-bg"></div>
</template>
<script>
    import 'particles.js'

    export default {
        data() {
            return {
                stars1: 0,
                stars2: 0,
                stars3: 0
            }
        },
        mounted() {
            const root = this.$refs.animatedBg;
            //const sliceSize = 400;
            const numberSlices = 5;
            const size = Math.sqrt(
                Math.pow(window.innerWidth, 2) + Math.pow(window.innerHeight, 2)
            );
            const sliceSize = Math.ceil(size/numberSlices);
            //const numberSlices = Math.ceil(size / sliceSize);

            const startPointX = (window.innerWidth - size) / 2;
            const startPointY = (window.innerHeight - size) / 2;

            root.style.left = startPointX + "px";
            root.style.top = startPointY + "px";
            root.style.width = size + "px";
            root.style.height = size + "px";

            let posY = 0;
            let posX = 0;
            let hasSubs = true;
            for (var i = 0; i < numberSlices; i++) {
                for (var j = 0; j < numberSlices; j++) {
                    const slice = this.createSlice(hasSubs);
                    slice.style.width = sliceSize + "px";
                    slice.style.height = sliceSize + "px";
                    slice.style.top = posY + "px";
                    slice.style.left = posX + "px";
                    root.append(slice);
                    posX += sliceSize;
                }
                hasSubs = !hasSubs;
                posY += sliceSize;
                posX = 0;
            }
        },
        methods: {
            createSlice: function (hasSubs) {
                const slice = document.createElement("div");
                slice.classList.add("slice");
                if (hasSubs) {
                    slice.classList.add("has-subs");
                    const secondSubSlice1 = document.createElement("div");
                    secondSubSlice1.classList.add("secondary-subslice", "sb1");
                    secondSubSlice1.append(document.createElement("div"));

                    const secondSubSlice2 = document.createElement("div");
                    secondSubSlice2.classList.add("secondary-subslice", "sb2");
                    secondSubSlice2.append(document.createElement("div"));

                    const secondSubSlice3 = document.createElement("div");
                    secondSubSlice3.classList.add("secondary-subslice", "sb3");
                    secondSubSlice3.append(document.createElement("div"));

                    const secondSubSlice4 = document.createElement("div");
                    secondSubSlice4.classList.add("secondary-subslice", "sb4");
                    secondSubSlice4.append(document.createElement("div"));

                    slice.append(secondSubSlice1);
                    slice.append(secondSubSlice2);
                    slice.append(secondSubSlice3);
                    slice.append(secondSubSlice4);
                }

                const PrimarySubSlice = document.createElement("div");
                PrimarySubSlice.classList.add("primary-subslice");
                PrimarySubSlice.append(document.createElement("div"));

                slice.append(PrimarySubSlice);

                return slice;
            },
            randomVerticalPosition: function () {
                return Math.floor(Math.random() * window.innerHeight);

            },
            randomHorizentalPosition: function () {
                return Math.floor(Math.random() * window.innerWidth);
            }
        }
    }
</script>
<style lang="scss">
    /*.bg-stars {
        background: radial-gradient(ellipse at bottom, var(--first-bg-color) 0%, var(--second-bg-color) 100%);
        background-repeat: no-repeat;
        position: fixed;
        width: 100vw;
        height: 100vh;
        top: 0;
        z-index: -2;

        > div {
            position: fixed;
            width: 100vw;
            height: 100vh;
            top: 0;
        }
    }*/
    body {
        background-color: var(--first-bg-color)  !important;
        &.transition-active {
            .animated-bg {
                animation-play-state: paused;
                .slice {
                    animation-play-state: paused;
                    .primary-subslice {
                        animation-play-state: paused;
                    }
                    .secondary-subslice {
                        animation-play-state: paused;
                    }
                }
            }
        }
    }
    .animated-bg {
        position: fixed;
        transform-origin: 50% 50%;
        animation: spin 60s linear infinite;
        z-index: -2;
        .slice {
            position: absolute;
            //border: 1px solid red;
            &.has-subs {
                animation: slice 16s ease infinite;
            }

            .secondary-subslice {
                position: absolute;
                width: 33.33%;
                height: 33.33%;
                animation: bigTwist 1s ease infinite;
                div {
                    width: 100%;
                    height: 100%;
                    background-color: var(--second-bg-color);
                    animation: pulse 2s ease infinite;
                }
                &.sb1 {
                    top: -16.5%;
                    left: -16.5%;
                }
                &.sb2 {
                    top: -16.5%;
                    right: -16.5%;
                }
                &.sb3 {
                    bottom: -16.5%;
                    left: -16.5%;
                }
                &.sb4 {
                    bottom: -16.5%;
                    right: -16.5%;
                }
            }
            .primary-subslice {
                position: absolute;
                width: 70%;
                height: 70%;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                margin: auto;
                //animation: bigTwist 1s ease infinite;
                animation: primarySlice 8s ease infinite;
                div {
                    width: 100%;
                    height: 100%;
                    background-color: var(--second-bg-color);
                    //animation: pulse 1s ease infinite;
                }
            }
        }
    }

    @keyframes spin {
        from {
            transform: rotate(0);
        }
        to {
            transform: rotate(360deg);
        }
    }
    @keyframes bigTwist {
        0%,
        10% {
            transform: rotate(0);
        }
        100%,
        50% {
            transform: rotate(90deg);
        }
    }

    @-webkit-keyframes pulse {
        50% {
            -webkit-transform: scale(1.15, 1.15);
        }
    }
    @-webkit-keyframes spin {
        from {
            -webkit-transform: rotate(0);
        }
        to {
            -webkit-transform: rotate(360deg);
        }
    }
    @-webkit-keyframes bigTwist {
        0%,
        10% {
            -webkit-transform: rotate(0);
        }
        100%,
        50% {
            -webkit-transform: rotate(90deg);
        }
    }


    @-webkit-keyframes primarySlice {
        0% {
            transform: scale(.2);
        }
        10% {
            transform: scale(.3);
        }
        30% {
            transform: scale(.3);
        }
        50% {
            transform: scale(1.1);
        }
        80% {
            transform: scale(1.1);
        }
        100% {
            transform: scale(.2);
        }
    }

    @-webkit-keyframes slice {
        0% {
            transform: rotate(0deg);
        }
        12.5% {
            transform: rotate(45deg);
        }
        25% {
            transform: rotate(90deg);
        }
        37.5% {
            transform: rotate(135deg);
        }
        50% {
            transform: rotate(180deg);
        }
        62.5% {
            transform: rotate(225deg);
        }
        75% {
            transform: rotate(270deg);
        }
        87.5% {
            transform: rotate(315deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
</style>